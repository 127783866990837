import { useTheme as useThemeMUI } from '@mui/material';
import axios from 'axios';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useEffect, useMemo, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import Lottie from 'react-lottie';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import imageBandeiras from '../../assets/bandeiras.png';
import logo from '../../assets/login/logo.png';
import threeDotLoading from '../../assets/lotties/3-dots-loading.json';
import { Button, Input } from '../../components/commonComponents/commonComponents';
import ToastGame from '../../components/toastGame/ToastGame';
import api from '../../service/api';
import { setRefreshToken, setToken } from '../../service/auth';
import gameInitStore from '../../store/gameInitStore';
import constantes from '../../utils/constantes';
import { useAuth } from '../auth/authProvider/AuthProvider';

import {
    Container,
    ContainerFomrResume,
    ContainerFotoTituloPagamento,
    ContainerImagensPagamento,
    ContainerResumoFatura,
    DivConteudoResumoFatura,
    DivHeaderCursoInfo,
    DivInputInfo,
    FormEcommerce,
    ImgPagamento,
    InfoDetalhesCurso,
} from './stylesPagamento';

import dayjs from 'dayjs';
import imgPagarme from '../../assets/IconePagarME.png';
import imgSeguroSSLB from '../../assets/IconeSiteSeguroSSLB.png';
import imgGarantia from '../../assets/garantia-7-dias.png';
import Promocao from '../../components/promocao/Promocao';
import isGuid from '../../utils/isGuid';

export default function Pagamento() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const theme = useTheme();
    const themeMui = useThemeMUI();
    const [searchParams] = useSearchParams();
    const [mostraLoad, setMostraLoad] = useState(false);
    const [lead, setLead] = useState(null);
    const [cupomCompra, setCupomCompra] = useState('');
    const [dadosCurso, setDadosCurso] = useState({});
    const [compraRealizada, setCompraRealizada] = useState(false);
    const [cupomInfo, setCupomInfo] = useState();
    const [preencheuCartao, setPreencheuCartao] = useState(true);
    const [hotjarAtivo, setHotjarAtivo] = useState(false);
    const [erroCritico, setErroCritico] = useState('');
    const paramsQueryRoot = Object.fromEntries([...searchParams]);

    const [erros, setErros] = useState({
        nome: null,
        email: null,
        celular: null,
        cpf: null,
        nascimento: null,
        cep: null,
        rua: null,
        bairro: null,
        cidade: null,
        estado: null,
        numero: null,
    });

    const defaultOption = {
        loop: true,
        autoplay: true,
        animationData: threeDotLoading,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const [dadosCompra, setDadosCompra] = useState({
        nomeCompleto: paramsQueryRoot?.nome || '',
        email: paramsQueryRoot?.email || '',
        celular: paramsQueryRoot?.celular || '',
        cpf: '',
        nascimento: undefined,
        cep: '',
        endereco: '',
        bairro: '',
        cidade: '',
        estado: '',
        numeroEndereco: '',
        complementoEndereco: '',
        nomeCartao: '',
        numeroCartao: '',
        cvvCartao: '',
        validadeCartaoMes: '',
        validadeCartaoAno: '',
        parcelas: 12,
        valor: 0,
    });

    const promocaoValida = (dadosCurso) => {
        return dadosCurso?.promocao_dtIncio &&
            new Date() > new Date(dadosCurso.promocao_dtIncio) &&
            new Date() < new Date(dadosCurso.promocao_dtFim)
            ? true
            : false;
    };

    async function initHotjar() {
        try {
            const { data: codigos } = await axios.get(`${constantes.APIUrl}Rastreio/Hotjar`);
            if (!codigos || codigos == 'Teste') return console.log('Codigos Hotjar não cadastrados');

            if (codigos) {
                try {
                    console.log('hotjar', codigos.codigoHJID, codigos.codigoHJSV);
                    hotjar.initialize(codigos.codigoHJID, codigos.codigoHJSV);
                    console.log('Ativando Hotjar');
                    setHotjarAtivo(true);
                } catch (error) {
                    console.error('Erro ao tentar inicializar hotjar na pagina de pagamento.');
                    console.error(error);
                }
            }
        } catch (error) {
            console.error('Erro ao tentar fazer inicialização no Hotjar.');
            console.error(error);
        } finally {
        }
    }

    useEffect(() => {
        if (location.search || (params.curosAlias && params.leadAlias)) {
            const paramsQuery = Object.fromEntries([...searchParams]);
            let { alias = '', cupom, lead = '' } = paramsQuery;
            if ((params.curosAlias && params.leadAlias) || !isGuid(lead)) {
                carregaLeadByAlias(params.leadAlias || lead);
                alias = params.curosAlias || alias;
            } else {
                setLead(lead);
            }

            if (cupom) {
                setCupomCompra(cupom);
                carregaCumpomInfo(cupom);
            }
            setLead(lead);
            carregaDadosCurso(alias);
            // carregaCumpomInfo(cupom);
            initHotjar();
        } else {
            if (location.state) {
                setDadosCurso(location.state);
            } else {
                setErroCritico('Curso não encontrado');
                toast.warning(<ToastGame title={'Curso não encontrado'} text={'Aviso'} />);
            }
        }
    }, []);

    const AnosSelect = () => {
        let anoAtual = new Date().getFullYear();
        let ultsDigits = String(anoAtual).substring(2, String(anoAtual).length);
        const anoFinal = parseInt(ultsDigits) + 20;
        let arrayAnos = [];
        while (ultsDigits < anoFinal) {
            arrayAnos.push(ultsDigits);
            ultsDigits++;
        }
        return arrayAnos.map((ano) => (
            <option key={ano} value={String(ano)}>
                {ano}
            </option>
        ));
    };

    const valorComPromocao = useMemo(() => {
        if (!dadosCurso) return 0;
        return promocaoValida(dadosCurso)
            ? dadosCurso.valor - dadosCurso.valor * (dadosCurso.promocao_percentualDesconto / 100)
            : dadosCurso.valor;
    }, [dadosCurso]);

    const ParcelasSelect = () => {
        const arrayParcelas = [];
        for (let index = 1; index <= 12; index++) {
            arrayParcelas.push(index);
        }

        return arrayParcelas.map((parcela) => (
            <option value={parcela}>
                {parcela} x{' '}
                {(Calcula(valorComPromocao, cupomInfo) / parcela).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}{' '}
                sem juros
            </option>
        ));
    };
    function mostraCampo(campo) {
        if (campo) return 'block';
        else return 'none';
    }
    async function carregaDadosCurso(aliasCurso) {
        try {
            setMostraLoad(true);
            // const responseDadosCurso = await gameInitStore.getJornada(aliasCurso);
            const response = await api.post(`curso/alias/${aliasCurso}`);
            setMostraLoad(false);
            if (response.data) {
                const responseDadosCurso = response.data;
                setDadosCurso(responseDadosCurso);
                return;
            }
            throw { message: 'Não foi possível carregar informações do curso.' };
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame title={'Erro'} text={error.message || 'Não foi possível carregar informações do curso.'} />);
            setMostraLoad(false);
            setErroCritico(error.message || 'Não foi possível carregar informações do curso.');
        }
    }
    async function carregaLeadByAlias(aliasLead) {
        try {
            setMostraLoad(true);
            const response = await api.get(`leads/adm/alias/${aliasLead}`);

            if (response.data) {
                const responseLead = response.data;
                setLead(responseLead.id);
                setMostraLoad(false);
                return responseLead.id;
            } else {
                if (response?.response?.data?.message) {
                    throw { message: response?.response?.data?.message };
                }
                throw { message: 'Não foi possível carregar informações do Lead.' };
            }
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame title={'Erro'} text={error.message || 'Não foi possível carregar informações do Lead.'} />);
            setMostraLoad(false);
            setErroCritico(error.message || 'Não foi possível carregar informações do Lead.');
        }
    }
    async function carregaCumpomInfo(cupom) {
        try {
            if (!cupom) return;
            const cumpomResponse = await api.get(`/stone/GetCupom?nomecupom=${cupom}`);

            if (cumpomResponse.status == 204) {
                throw 'Cupom inexistente.';
            } else {
                if (cumpomResponse.data.percentual == '100') {
                    setPreencheuCartao(true);
                }
            }
            setCupomInfo(cumpomResponse.data);
        } catch (error) {
            console.error(error);
            toast.error(<ToastGame text={'Erro ao carregar informações do cupom.'} title={'Erro no Cupom'} />);
        }
    }
    async function carregaDadosCep(cep) {
        try {
            if (cep.length == 8) {
                const responseCarregaDadosCep = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                if (responseCarregaDadosCep.status == 200) {
                    const { data } = responseCarregaDadosCep;
                    const { logradouro, complemento, bairro, localidade, uf, erro } = data;
                    if (erro) {
                        toast.warning(<ToastGame title="CEP inválido ou não encontrado." text="Aviso" />);
                    } else {
                        setDadosCompra({
                            ...dadosCompra,
                            endereco: logradouro,
                            complementoEndereco: complemento,
                            bairro,
                            cidade: localidade,
                            estado: uf,
                        });
                    }
                }
            }
        } catch (error) {
            toast.error(<ToastGame title={error || 'Não foi possível carregar informações do cep.'} text="Erro" />);
        }
    }
    async function acessarSistema() {
        const aluno = await gameInitStore.getAluno();
        if (!aluno)
            return toast.info(
                <ToastGame
                    title={'Erro ao buscar aluno'}
                    text={'Erro ao tentar buscar dados do aluno, por favor entre em contato com suporte.'}
                />,
            );
        auth.signin(aluno, async () => {
            navigate('/app', { replace: true, state: { trocarSenha: true } });
        });
    }
    async function enviaPagamento() {
        try {
            setMostraLoad(true);
            if (!lead) {
                toast.warning(<ToastGame title={'Lead não encontrada'} text={'Aviso'} />);
                setMostraLoad(false);
                return;
            }

            const data = {
                ...dadosCompra,
                nomeCompleto: dadosCompra?.nomeCartao || dadosCompra.nomeCompleto,
                preco: dadosCurso.valor,
                numeroParcelas: dadosCompra.parcelas,
                idLead: lead,
                idAutor: dadosCurso?.autor?.id,
                idCurso: dadosCurso?.id,
                validadeCartao: `${dadosCompra.validadeCartaoMes}${dadosCompra.validadeCartaoAno}`,
                nomeCurso: dadosCurso?.nome,
                stoneTransaction: '',
                cupom: cupomCompra,
                comprarNovamente: true,
            };

            data.cep = data.cep.replace(/[^0-9\.]+/g, '');
            data.cpf = data.cpf.replace(/[^0-9\.]+/g, '');
            data.numeroEndereco = data.numeroEndereco.replace(/[^0-9\.]+/g, '');
            data.celular = data.celular.replace(/[^0-9\.]+/g, '');

            const regexCEP = /^\d{8}$/;
            if (!regexCEP.test(data.cep)) {
                setMostraLoad(false);
                return toast.warning(<ToastGame title={'CEP Incorreto'} text={'CEP não esta no formato correto.'} />);
            }

            const regexCelular = /^\d{2}9\d{8}$/;
            if (!regexCelular.test(data.celular)) {
                setMostraLoad(false);
                return toast.warning(<ToastGame title={'Celular Invalido'} text={'Celular não esta no formato correto.'} />);
            }

            if (data.cpf.length == 11) {
                if (!cpf.isValid(data.cpf)) {
                    setMostraLoad(false);
                    return toast.warning(<ToastGame title={'CPF invalido'} text={'CPF invalido, por favor verifique.'} />);
                }
            } else {
                if (!cnpj.isValid(data.cpf)) {
                    setMostraLoad(false);
                    return toast.warning(<ToastGame title={'CNPJ invalido'} text={'CNPJ invalido, por favor verifique.'} />);
                }
            }

            const responseTransactionStone = await axios.post(`${constantes.APIUrl}stone`, data);

            if (responseTransactionStone.status == 200) {
                setToken(responseTransactionStone.data.token);
                setRefreshToken(responseTransactionStone.data.refreshToken);
                ReactPixel.track('Purchase', { value: dadosCurso.valor, currency: 'BRL', content_ids: dadosCurso?.id });
                navigate('/compraFinalizada');
            }
            setMostraLoad(false);
        } catch (error) {
            setMostraLoad(false);
            if (error?.response) {
                toast.warning(<ToastGame title={'Problema no pagamento'} text={error.response.data} />);
            } else {
                console.error(error);
                toast.warning(<ToastGame title={`Erro, Transação não realizada!`} text={'Problema no pagamento'} />);
                throw `Transação não realizada`;
            }
        }
    }
    function Calcula(valor, cupom) {
        if (!cupom) {
            return valor;
        } else {
            return valor - (valor * parseInt(cupom.percentual)) / 100;
        }
    }
    function mostrarDadosCadastrais() {
        const cardValidations = window.pagarme.validate({
            card: {
                card_number: dadosCompra.numeroCartao,
                card_holder_name: dadosCompra.nomeCartao,
                card_expiration_date: `${dadosCompra.validadeCartaoMes}${dadosCompra.validadeCartaoAno}`,
                card_cvv: dadosCompra.cvvCartao,
            },
        });

        if (!dadosCompra.nomeCartao)
            toast.warning(<ToastGame title={'Problema no pagamento'} text={'Nome no cartão inválido'} />);
        if (!cardValidations.card.card_cvv)
            toast.warning(<ToastGame title={'Problema no pagamento'} text={'Número no cartão inválido!'} />);
        if (!cardValidations.card.card_expiration_date)
            toast.warning(<ToastGame title={'Problema no pagamento'} text={'Data no cartão inválida!'} />);
        if (!cardValidations.card.card_holder_name)
            toast.warning(<ToastGame title={'Problema no pagamento'} text={'Nome no cartão inválido'} />);
        if (!cardValidations.card.card_number)
            toast.warning(<ToastGame title={'Problema no pagamento'} text={'Número no cartão inválido!'} />);
        let naoPossuiErros = true;

        for (const key in cardValidations.card) {
            if (Object.hasOwnProperty.call(cardValidations.card, key)) {
                const element = cardValidations.card[key];
                if (!element) {
                    naoPossuiErros = false;
                    console.log(`Parou no erro do item ${key}`, element);
                }
            }
        }

        if (naoPossuiErros) {
            setPreencheuCartao(true);
        } else {
            toast.warning(
                <ToastGame
                    title={'Problema no pagamento'}
                    text={'Existem erros nos dados do seu cartão, por favor verifique os mesmos'}
                />,
            );
        }
    }

    if (erroCritico) {
        return (
            <Container style={{ height: '100vh' }}>
                <h1>Opss! Ocorreu um problema, que impossibilita de abrir esta pagina corretamente.</h1>
                <h2 style={{ color: themeMui.palette.error.main }}>{erroCritico}</h2>
                <span style={{ color: '#bdbaba', textAlign: 'start' }}>
                    Precisa de ajuda?{' '}
                    <a
                        style={{ color: themeMui.palette.primary.main }}
                        target="_blank"
                        href={`https://api.whatsapp.com/send?phone=5541998704060&text=${encodeURI(
                            `Olá, estou tendo problemas para realizar meu pagamento do curso '${dadosCurso?.nome}'. U
                                Um erro apareceu para mim: *${erroCritico}* 
                                Na pagina ${window.location.href.replace('http://', '')}
                            `,
                        )}`}
                    >
                        Entre em contato e tire suas duvidas.
                    </a>
                </span>
            </Container>
        );
    }

    return (
        <Container promo={promocaoValida(dadosCurso)}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // background: '#232222',
                    width: '100%',
                    padding: '12px 0px',
                    height: '60px',
                    // boxShadow: 'rgb(0, 0, 0) 0px 0px 10px 2px',
                }}
            >
                <img src={constantes.logo} onError={(e) => (e.target.src = logo)} height={'100%'} />
            </div>
            <ContainerFomrResume>
                <DivHeaderCursoInfo>
                    <div>
                        <ContainerFotoTituloPagamento>
                            <ImgPagamento src={dadosCurso?.urlImagem} />
                            <div
                                style={{
                                    flex: 1,
                                    paddingLeft: '30px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <h1 style={{ fontWeight: '600', color: themeMui.palette.primary.main, marginBottom: '16px' }}>
                                        {dadosCurso?.nome}
                                    </h1>
                                </div>
                                <span style={{ textAlign: 'start', color: '#fff', marginBottom: '15px' }}>
                                    {dadosCurso?.descricaoResumida} <br /> Com {dadosCurso?.autor?.nome}
                                </span>
                            </div>
                        </ContainerFotoTituloPagamento>
                        <InfoDetalhesCurso>
                            {/* <h3 style={{ textAlign: 'start', marginTop: '0px' }}>{dadosCurso?.nome}</h3> */}
                            <h5 className="subTitle">Resumo da Compra</h5>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    padding: '15px 0px',
                                    gap: '5px',
                                }}
                            >
                                {/* <span>Autor: {dadosCurso?.autor?.nome}</span> */}
                                {cupomInfo && (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <span>De:</span>
                                        <span
                                            style={{
                                                fontWeight: '600',
                                                textDecoration: 'line-through',
                                                color: '#8a8888',
                                            }}
                                        >
                                            {valorComPromocao?.toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}{' '}
                                        </span>
                                    </div>
                                )}
                                {cupomInfo && (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <span>Cupom Aplicado:</span>
                                        <span
                                            style={{ fontWeight: '600' }}
                                        >{`(${cupomInfo.percentual}%) ${cupomInfo.cupom}`}</span>
                                    </div>
                                )}
                                {Calcula(valorComPromocao, cupomInfo) == 0 ? (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <span>Por: </span>
                                        <span
                                            style={{
                                                textAlign: 'start',
                                                fontWeight: '600',
                                            }}
                                        >
                                            R$ 0,00 (Cortesia)
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            marginTop: '0px',
                                        }}
                                    >
                                        <span>Por:</span>
                                        <span
                                            style={{
                                                textAlign: 'start',
                                                fontWeight: '600',
                                            }}
                                        >
                                            12 x de{' '}
                                            {(Calcula(valorComPromocao, cupomInfo) / 12)?.toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}{' '}
                                            no cartão
                                        </span>
                                    </div>
                                )}
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <span style={{ fontSize: '24px', color: themeMui.palette.primary.main }}>Total:</span>
                                    <span style={{ fontWeight: '600', fontSize: '24px', color: themeMui.palette.primary.main }}>
                                        {Calcula(valorComPromocao, cupomInfo)?.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        })}
                                    </span>
                                </div>
                                {promocaoValida(dadosCurso) && (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <span style={{ fontSize: '24px', color: themeMui.palette.primary.main }}>
                                            {`Valor promocional valido até ${dayjs(dadosCurso.promocao_dtFim).format(
                                                'DD/MM/YYYY h:mm',
                                            )}`}
                                        </span>
                                    </div>
                                )}
                            </div>

                            {/* {Calcula(dadosCurso?.valor, cupomInfo) > 0 && (
                                <h1 style={{ fontSize: '24px', width: '100%' }}>
                                    Total{' '}
                                    {Calcula(dadosCurso?.valor, cupomInfo)?.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </h1>
                            )} */}
                            {/* <img style={{ width: '100%' }} src={imgBandeiras} /> */}
                        </InfoDetalhesCurso>
                    </div>
                </DivHeaderCursoInfo>
                <FormEcommerce
                    id="pagandoForm"
                    onSubmit={(event) => {
                        event.preventDefault();
                        enviaPagamento();
                    }}
                >
                    {preencheuCartao && (
                        <>
                            <h5 className="subTitle">Dados de Cadastro</h5>

                            <DivInputInfo>
                                <span>Nome completo:</span>
                                <Input
                                    type="text"
                                    placeholder="Nome completo"
                                    maxlength="50"
                                    value={dadosCompra.nomeCompleto}
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, nomeCompleto: target.value })}
                                    required
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.nome) }}>
                                    {erros.nome}
                                </sub>
                            </DivInputInfo>

                            <DivInputInfo>
                                <span>Email:</span>
                                <Input
                                    required
                                    type="email"
                                    id="email"
                                    value={dadosCompra.email}
                                    placeholder="Email"
                                    maxlength="200"
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, email: target.value })}
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.email) }}>
                                    {erros.email}
                                </sub>
                            </DivInputInfo>
                            <DivInputInfo>
                                <span>Celular:</span>
                                <Input
                                    required
                                    type="text"
                                    placeholder="Celular com DDD ex: 21984438518"
                                    id="phonenumber"
                                    value={dadosCompra.celular}
                                    maxlength="20"
                                    pattern="^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$"
                                    title="ex: 21988887777"
                                    onChange={({ target }) =>
                                        setDadosCompra({
                                            ...dadosCompra,
                                            celular: String(target.value).replace(/\s/g, '').replace(/\D/g, ''),
                                        })
                                    }
                                    onBlur={({ target }) =>
                                        setDadosCompra({
                                            ...dadosCompra,
                                            celular: String(target.value).replace(/\s/g, '').replace(/\D/g, ''),
                                        })
                                    }
                                    onblur="this.value = this.value.replace(/\s/g, '')"
                                    autoComplete="tel"
                                    x-autocompletetype="tel"
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.celular) }}>
                                    {erros.celular}
                                </sub>
                            </DivInputInfo>

                            <DivInputInfo>
                                <span>Nascimento:</span>
                                <Input
                                    required
                                    type="date"
                                    id="nascimento"
                                    value={dadosCompra.nascimento}
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, nascimento: target.value })}
                                    placeholder="Data de nascimento"
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.nascimento) }}>
                                    {erros.nascimento}
                                </sub>
                            </DivInputInfo>
                            <DivInputInfo>
                                <span>CPF/CNPJ:</span>
                                <Input
                                    required
                                    title="Preencha um cpf ou cpnj valido"
                                    type="text"
                                    placeholder="CPF ou CNPJ"
                                    minLength="11"
                                    id="cpfcnpj"
                                    value={dadosCompra.cpf}
                                    maxlength="18"
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, cpf: target.value })}
                                    onblur="this.value = this.value.replace(/\s/g, '')"
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.cpf) }}>
                                    {erros.cpf}
                                </sub>
                            </DivInputInfo>
                            <DivInputInfo>
                                <span>CEP:</span>
                                <Input
                                    required
                                    type="text"
                                    id="zipcode"
                                    value={dadosCompra.cep}
                                    minLength="8"
                                    maxLength="8"
                                    onblur="this.value = this.value.replace(/\s/g, '')"
                                    onBlur={({ target }) =>
                                        carregaDadosCep(String(target.value).replace(/\s/g, '').replace(/\D/g, ''))
                                    }
                                    onChange={({ target }) =>
                                        setDadosCompra({
                                            ...dadosCompra,
                                            cep: String(target.value).replace(/\s/g, '').replace(/\D/g, ''),
                                        })
                                    }
                                    pattern="^[0-9]*$"
                                    placeholder="CEP, somente números ex: 22229360"
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.cep) }}>
                                    {erros.cep}
                                </sub>
                            </DivInputInfo>
                            <DivInputInfo className="metade">
                                <span>Número:</span>
                                <Input
                                    required
                                    type="text"
                                    placeholder="Somente números"
                                    id="number"
                                    value={dadosCompra.numeroEndereco}
                                    pattern="^[0-9]*$"
                                    maxlength="20"
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, numeroEndereco: target.value })}
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.estado) }}>
                                    {erros.numero}
                                </sub>
                            </DivInputInfo>
                            <DivInputInfo className="inteiro">
                                <span>Nome do logradouro (Rua, avenida):</span>
                                <Input
                                    title="Esses dados são preenchidos automaticamente mediante o CEP"
                                    required
                                    type="text"
                                    id="addresss"
                                    ng-model="address"
                                    maxlength="50"
                                    onClick={() => carregaDadosCep(String(dadosCompra.cep).replace(/\s/g, '').replace(/\D/g, ''))}
                                    placeholder="Endereço preenchido automaticamente de acordo com CEP"
                                    value={dadosCompra.endereco}
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, endereco: target.value })}
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.rua) }}>
                                    {erros.rua}
                                </sub>
                            </DivInputInfo>

                            {/* <DivInputInfo className="inteiro">
                            <span>Complemento:</span>
                            <Input
                                type="text"
                                placeholder="Complemento residencial"
                                id="complement"
                                value={dadosCompra.complementoEndereco}
                                maxLength="25"
                                onChange={({ target }) => setDadosCompra({ ...dadosCompra, complementoEndereco: target.value })}
                            />
                        </DivInputInfo> */}

                            <DivInputInfo className="metade">
                                <span>Bairro:</span>
                                <Input
                                    title="Esses dados são preenchidos automaticamente mediante o CEP"
                                    required
                                    type="text"
                                    id="neighborhood"
                                    maxLength="20"
                                    placeholder="Bairro"
                                    onClick={() => carregaDadosCep(String(dadosCompra.cep).replace(/\s/g, '').replace(/\D/g, ''))}
                                    value={dadosCompra.bairro}
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, bairro: target.value })}
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.bairro) }}>
                                    {erros.bairro}
                                </sub>
                            </DivInputInfo>
                            <DivInputInfo className="metade">
                                <span>Cidade:</span>
                                <Input
                                    title="Esses dados são preenchidos automaticamente mediante o CEP"
                                    required
                                    type="text"
                                    id="city"
                                    value={dadosCompra.cidade}
                                    maxLength="20"
                                    placeholder="Cidade"
                                    onClick={() => carregaDadosCep(String(dadosCompra.cep).replace(/\s/g, '').replace(/\D/g, ''))}
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, cidade: target.value })}
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.bairro) }}>
                                    {erros.cidade}
                                </sub>
                            </DivInputInfo>
                            <DivInputInfo className="metade">
                                <span>Estado:</span>
                                <Input
                                    title="Esses dados são preenchidos automaticamente mediante o CEP"
                                    required
                                    type="text"
                                    id="state"
                                    value={dadosCompra.estado}
                                    onClick={() => carregaDadosCep(String(dadosCompra.cep).replace(/\s/g, '').replace(/\D/g, ''))}
                                    placeholder="Estado"
                                    maxLength="2"
                                    minLength="2"
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, estado: target.value })}
                                />
                                <sub className="errorLabel" style={{ display: mostraCampo(erros.estado) }}>
                                    {erros.estado}
                                </sub>
                            </DivInputInfo>
                        </>
                    )}

                    {(!cupomInfo?.percentual || cupomInfo.percentual < 100) && (
                        <>
                            <h5 className="subTitle">Dados de Pagamento</h5>

                            <DivInputInfo className="inteiro">
                                <span>Nome no cartão:</span>
                                <Input
                                    value={dadosCompra.nomeCartao}
                                    id="card_holder_name"
                                    type="text"
                                    required
                                    placeholder="Nome escrito no cartão"
                                    maxlength="30"
                                    autoComplete="cc-name"
                                    x-autocompletetype="cc-name"
                                    title="Preencha este campo exatamente como consta o nome no cartão."
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, nomeCartao: target.value })}
                                />
                                {/* <sub className="errorLabel" ng-if="erros.nomeCartao">{{erros.nomeCartao}}</sub>                          */}
                            </DivInputInfo>

                            <DivInputInfo className="inteiro">
                                <span>Número do cartão:</span>
                                <Input
                                    id="card_number"
                                    type="number"
                                    placeholder="Número do cartão"
                                    autoComplete="cc-number"
                                    x-autocompletetype="cc-number"
                                    required
                                    min="1"
                                    title="Preencha este campo com número que consta em seu cartão."
                                    value={dadosCompra.numeroCartao}
                                    onChange={({ target }) => setDadosCompra({ ...dadosCompra, numeroCartao: target.value })}
                                />
                                {/* <sub className="errorLabel" ng-if="erros.numeroCartao">{{erros.numeroCartao}}</sub> */}
                            </DivInputInfo>
                            <DivInputInfo className="metade" style={{ width: '320px' }}>
                                <span>Código de segurança(CVV):</span>
                                <Input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="4"
                                    minLength="3"
                                    required
                                    placeholder="CVV até 4 digitos"
                                    ng-model="card_cvv"
                                    id="card_cvv"
                                    title="Preencha este campo com numero CVV, geralmente este numero se encontra na parte detrás do cartão"
                                    value={dadosCompra.cvvCartao}
                                    onChange={({ target }) =>
                                        setDadosCompra({
                                            ...dadosCompra,
                                            cvvCartao: String(target.value).replace(/\s/g, '').replace(/\D/g, ''),
                                        })
                                    }
                                />
                                {/* <sub className="errorLabel" ng-if="erros.cvvCartao">{{erros.cvvCartao}}</sub> */}
                            </DivInputInfo>
                            <DivInputInfo className="metade">
                                <span style={{ flex: 0 }}>Validade do cartão:</span>
                                <div style={{ height: '40px', marginLeft: '5px', display: 'flex' }}>
                                    <select
                                        style={{ borderRadius: '6px', marginRight: '8px' }}
                                        required
                                        placeholder="Mês da validade"
                                        id="card_expiration_month"
                                        value={dadosCompra.validadeCartaoMes}
                                        autoComplete="cc-exp-month"
                                        x-autocompletetype="cc-exp-month"
                                        title="Preencha este campo o mês de validade do cartão."
                                        onChange={({ target }) =>
                                            setDadosCompra({ ...dadosCompra, validadeCartaoMes: target.value })
                                        }
                                    >
                                        <option value=""></option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <select
                                        style={{ borderRadius: '6px' }}
                                        required
                                        id="card_expiration_year"
                                        value={dadosCompra.validadeCartaoAno}
                                        autoComplete="cc-exp-year"
                                        x-autocompletetype="cc-exp-year"
                                        title="Preencha este campo o ano de validade do cartão."
                                        onChange={({ target }) =>
                                            setDadosCompra({ ...dadosCompra, validadeCartaoAno: target.value })
                                        }
                                    >
                                        <option value=""></option>
                                        <AnosSelect />
                                    </select>
                                </div>
                                {/* <sub className="errorLabel" ng-if="erros.validadeCartao">{{erros.validadeCartao}}</sub> */}
                            </DivInputInfo>
                            <DivInputInfo style={{ marginTop: '10px', padding: '0 0 12px 0' }}>
                                <span style={{ flex: 0 }}>Numero de Parcelas:</span>
                                <div style={{ height: '40px', marginLeft: '5px', display: 'flex' }}>
                                    <select
                                        style={{ flex: 1, borderRadius: '6px' }}
                                        value={dadosCompra.parcelas}
                                        onChange={({ target }) => setDadosCompra({ ...dadosCompra, parcelas: target.value })}
                                    >
                                        <ParcelasSelect />
                                    </select>
                                </div>
                            </DivInputInfo>

                            <h5 className="subTitle">Cupom de Desconto</h5>
                            <DivInputInfo className="inteiro">
                                <span>Cupom de desconto:</span>
                                <Input
                                    onBlur={({ target }) => carregaCumpomInfo(target.value)}
                                    value={cupomCompra}
                                    id="cupom"
                                    type="text"
                                    placeholder="Cupom de desconto"
                                    title="Cupom de desconto."
                                    style={{ color: '#000' }}
                                    onChange={({ target }) => setCupomCompra(target.value)}
                                />
                                {/* <sub className="errorLabel" ng-if="erros.nomeCartao">{{erros.nomeCartao}}</sub>                          */}
                            </DivInputInfo>
                        </>
                    )}

                    <ContainerResumoFatura>
                        <h5 className="subTitle">Resumo da compra</h5>

                        <div>
                            <DivConteudoResumoFatura>
                                <strong>{dadosCurso?.nome}</strong>{' '}
                                <div style={{ display: 'flex' }}>
                                    {cupomInfo && (
                                        <>
                                            <span style={{ marginRight: '5px' }}>De</span>
                                            <span
                                                style={{
                                                    marginRight: '10px',
                                                    textDecoration: 'line-through',
                                                    color: '#cecece',
                                                }}
                                            >
                                                {valorComPromocao?.toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}{' '}
                                            </span>
                                            <span style={{ marginRight: '5px' }}>Por</span>
                                        </>
                                    )}
                                    <span>
                                        {Calcula(valorComPromocao, cupomInfo)?.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        })}
                                    </span>
                                </div>
                            </DivConteudoResumoFatura>
                            {/* <div><strong>{dadosCurso.nome}</strong> {dadosCurso.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div> */}
                            <DivConteudoResumoFatura
                                style={{
                                    marginTop: '10px',
                                    fontWeight: '600',
                                    fontSize: '24px',
                                    color: themeMui.palette.primary.main,
                                }}
                            >
                                <strong>{dadosCompra?.parcelas == 1 ? 'Total(à vista)' : 'Total Parcelado em '}</strong>{' '}
                                {dadosCompra?.parcelas}x{' de '}
                                {(Calcula(valorComPromocao, cupomInfo) / dadosCompra?.parcelas)?.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </DivConteudoResumoFatura>
                        </div>
                    </ContainerResumoFatura>
                    {preencheuCartao && (
                        <div style={{ width: '100%', display: 'flex', marginTop: '12px' }}>
                            <Button style={{ flex: 1, background: theme.colors.verde }}>Comprar Curso</Button>
                        </div>
                    )}
                    <ContainerImagensPagamento>
                        <img src={imgGarantia} title="Satisfação 100% garantida, 7 de garantia." />
                        <img src={imgPagarme} title="Sistema de pagamento Pagar.me(stone)." />
                        <img src={imgSeguroSSLB} title="Certificado SSL. Site seguro." />
                        <img src={imageBandeiras} title="Bandeiras aceitas" />
                    </ContainerImagensPagamento>
                    <span style={{ color: '#bdbaba', textAlign: 'start' }}>
                        *Ao clicar em 'Adquirir Curso', eu concordo (i) com os Termos de Compra (ii) que li e estou ciente da
                        Política de Privacidade e (iii) que sou maior de idade ou autorizado e acompanhado por um tutor legal.
                    </span>
                    <div style={{ marginTop: '15px', width: '100%' }}>
                        <span style={{ color: '#bdbaba', textAlign: 'start' }}>
                            Precisa de ajuda?{' '}
                            <a
                                style={{ color: themeMui.palette.primary.main }}
                                target="_blank"
                                href={`https://api.whatsapp.com/send?phone=5521995826886&text=${encodeURI(
                                    `Olá, estou com duvidas para realizar meu pagamento do curso '${dadosCurso?.nome}'`,
                                )}`}
                            >
                                Entre em contato e tire suas duvidas.
                            </a>
                        </span>
                    </div>
                </FormEcommerce>
            </ContainerFomrResume>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    padding: '12px 0px',
                    marginTop: '15px',
                }}
            >
                <div>
                    {/* <span >Power By: </span> */}
                    <a href="https://info.medx.med.br/" target="_blank">
                        <img style={{ height: '40px' }} src={constantes.logo} onError={(e) => (e.target.src = logo)} />
                    </a>
                </div>
                <span style={{ fontSize: '12px' }}>Curso online utilizando tecnologia Gammyx.</span>

                <span style={{ fontSize: '12px' }}>MEDX Tecnologia S/A. ®️ {new Date().getFullYear()}</span>
            </div>
            {mostraLoad && (
                <div
                    style={{
                        position: 'fixed',
                        background: '#0000006b',
                        display: 'flex',
                        width: '100vw',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: 0,
                        zIndex: 10,
                    }}
                >
                    <Lottie options={defaultOption} style={{ width: '300px', height: '300px' }} />
                </div>
            )}

            {promocaoValida(dadosCurso) && <Promocao dadosCurso={dadosCurso} />}
        </Container>
    );
}
